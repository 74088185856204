<template>
  <div style="width: 400px">
    <win-layer-pop class="sample_popup_list">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="parentInfoRe"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'
import demInvoicePop from '@/pages/settle/popup/DemInvoicePop'

export default {
  name: 'VirtualAccountResult',
  components: {
    demInvoicePop,
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          invIssNo: ''
        }
      }
    }
  },
  data () {
    return {
      klnetUrl: '',
      customComponent: null,
      parentInfoRe: {}
    }
  },
  created () {
    // 초기 화면 세팅
    this.init()
  },
  methods: {
    // 가상계좌 발급 화면 단계별 조회
    async init () {
      try {
        const blNo = window.localStorage.getItem('vaBlNo')
        const invIssNo = window.localStorage.getItem('vaInvIssNo')

        let path = this.$route.path.split('/').slice(-1)
        if (path.length > 0) {
          if (path[0] === 'cancel') {
            //인보이스 취소 로직 호출
            let param = {
              blNo: blNo,
              invIssNo: invIssNo
            }

            window.opener.postMessage('cancel', '*')
          } else if (path[0] === 'complete') {
            //preview 호출 전에 klnet에서 리턴을 줬는지 체크하는 로직 ( 리턴 올때 까지 확인 로직 )
            await CalcIssue.checkVirtualAccountResult(invIssNo).then(response => {
              if (response.data === 'success') {
                window.opener.postMessage('success', '*')
              } else {
                alert('failed to check VirtualAccount result')
              }
            }).catch(e => {
              console.log(e)
            })
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        window.close()
      }
    },
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')
    }
  }
}
</script>

<style scoped>
</style>
